/* eslint-disable */
import React, { Component } from "react";
import axios from "../../utils/axios";
import {
  Tooltip,
  Modal,
  InputGroup,
  FormControl,
  Accordion,
  Spinner,
  Button,
  OverlayTrigger,
  Form,
  Alert,
  Row,
  Col,
  Tab,
  Popover,
} from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import Select from "react-select";
import { config } from "../../constants";
import { schedule_options } from "../../constants";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import LoadingBar from "react-top-loading-bar";
import * as Icon from "react-feather";
import "./taps.css";
import InputMask from "react-input-mask";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import moment from "moment-timezone";
import { isValidCron } from "cron-validator";
import WithRouter from "../../HOC/RouterWrapper";
import HorizontalDropdownMenu from "../../components/HorizontalDropdown";
import TablePagination from "../../components/TablePagentation";

import { ReactComponent as FailedIcon } from "../../assets/img/icons/failed.svg";
import { ReactComponent as SuccessIcon } from "../../assets/img/icons/success.svg";
import { ReactComponent as RefreshIcon } from "../../assets/img/icons/refresh.svg";
import { ReactComponent as DetailsIcon } from "../../assets/img/icons/details.svg";
import { ReactComponent as QueuedIcon } from "../../assets/img/icons/queed.svg";
import { ReactComponent as PausedIcon } from "../../assets/img/icons/pause.svg";
import { ReactComponent as StopIcon } from "../../assets/img/icons/StopIcon.svg";
import { ReactComponent as CancelIcon } from "../../assets/img/icons/CancelIcon.svg";
import { ReactComponent as SettingsIcon } from "../../assets/img/icons/SettingsIcon.svg";
import { ReactComponent as PlayIcon } from "../../assets/img/icons/PlayIcon.svg";
import { ReactComponent as PausingIcon } from "../../assets/img/icons/PausingIcon.svg";
import { ReactComponent as OptionsIcon } from "../../assets/img/icons/OptionsIcon.svg";

import SearchJobs from "../jobs/SearchJobs";
import LoadingTableBody from "../../components/Loading/LoadingTableBody";
import TapCreatorForm from "../../components/DagCreators/TapCreatorForm";
import DagHistory from "../jobs/DagHistory";
import RunningLottie from "../../components/RunningLottie";
import { stopTask } from "../functions.js";
import { fetchLastRuns } from "../functions.js";
import djlogo from './djlogo.svg'; // Import the SVG file stored in src/pages
import airbytelogo from './airbyte.png'; // Import the SVG file stored in src/pages
import fivetranlogo from './fivetran.png'; // Import the SVG file stored in src/pages
import SortBtn from "../../components/SortBtn.js";

// let GoogleLoginPage;
// if (process.env.NODE_ENV !== 'production') {
//   GoogleLoginPage = require('../../components/google_oauth/oauth.js').default;
// }

Sentry.init({
  dsn: "https://53a82b56123b43348a6579efda968a77@o4504469493645312.ingest.sentry.io/4504469494497280",
  integrations: [new BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,
});

const user = JSON.parse(localStorage.getItem("user"));
var customer_timezone = localStorage.getItem("timezone");

let dropdownSelec = [];

let timestamps = [{ 5: "2 days ago" }];
const timeFormatRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;

class NewTap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: 40,
      loading: false,
      get_dags_status: true,
      spec_details: [],
      global_stream_selected: false,
      global_replicate: false,
      global_selected_sync: "",
      tap_alias: "",
      datasources: [],
      schema_name: "",
      schema: "",
      running_taps: [],
      selected_streams: [],
      global_supportedSyncModes: [],
      custom_reports: [],
      modal_custom_reports: false,
      activeTab: "mytaps",
      historical_dag_runs: [],
      replication_disabled: true,
      dag_pause_statuses: [],
      saving_settings: false,
      loading_connectors: true,
      show_schedule_button: false,
      saving_disabled: true,
      loading_mytaps: true,
      selected_tap: "",
      delete_name: "",
      selected_job: "",
      dag_runs: [],
      streams: [],
      first_load: true,
      last_refresh: timestamps,
      mytasks: [],
      delete_id: "",
      color: "primary",
      embedded_url: false,
      schema_overwritten: false,
      saving: false,
      new_schema: false,
      deleting: false,
      searchConnectorsKeyword: "",
      jobNameFilters: "",
      currentPage: 1,
      perPage: 10,
      isShowingCreateTapForm: false,
      isShowingDagHistoryTable: false,
      sortedColumn: {
        column: "",
        isAsc: true,
      },
    };
    this.isComponentMounted = false; // Flag to track if the component is mounted
  }

  componentDidUpdate(prevProps) {
    const hasQueryParamsChanged =
      this?.props?.router?.location &&
      prevProps?.router?.location &&
      this.props.router.location.search !== prevProps.router.location.search;

    const hasInitialHistoryModalView =
      prevProps.router.location.search.includes("history_modal") ||
      this.props.router.location.search.includes("history_modal");

    if (hasQueryParamsChanged) {
      if (hasInitialHistoryModalView) {
        this.showInitialHistoryModal(prevProps);
      } else {
        this.fetchInitialTapData();
      }
    }
  }

  componentDidMount() {
    this.isComponentMounted = true;
    this.fetchInitialTapData();
  }

  handleStopTask = (display_name, datajolt_task_id) => {
    stopTask(display_name, datajolt_task_id, this.setState.bind(this));
  };

  setSelectedTapData = (data) => this.setState(data);

  getTargetDAGData = () => {
    const history_modal = new URLSearchParams(window.location.search).get(
      "history_modal"
    );
    const targetDAG = this.state.mytasks.find((DAG) => DAG.id == history_modal);
    console.log("history_modal");
    console.log(targetDAG);
    return targetDAG;
  };

  showInitialHistoryModal = (prevProps) => {
    //prevProps to extract queryParams from pervious render

    if (
      prevProps.router.location.search.includes("history_modal") &&
      !this.props.router.location.search.includes("history_modal")
    ) {
      this.setState({
        isShowingDagHistoryTable: false,
        historical_runs_available: false,
        modal_history: false,
      });
    }

    if (
      !prevProps.router.location.search.includes("history_modal") &&
      this.props.router.location.search.includes("history_modal")
    ) {
      if (this.getTargetDAGData()) {
        this.toggleHistoryDagTable();
      }
    }
  };

  componentWillUnmount = () => {
    this.fetchDAGLastRunsTimerMounted = false;
    this.isComponentMounted = false;
    clearTimeout(this.fetchDAGLastRunsTimeout);
  };

  toggleHistoryDagTable = () => {
    this.setState({
      isShowingDagHistoryTable: !this.state.isShowingDagHistoryTable,
    });
  };

  createFivetranTap = (connector_id, connector_name, schema, tap_alias) => {
    var toastID = toast.loading(
      "Importing your new " + connector_name + " tap from Fivetran..."
    );
    this.setState({ progress: 40 });
    axios
      .post(config.url.TAPS_URL + "/api/fivetran/create_tap", {
        tap_alias: tap_alias,
        connector_id: connector_id,
        connector_name: connector_name,
        schema: schema,
      })
      .then((response) => {
        toast.success("Your " + connector_name + " tap is now live! 🎉", {
          autoClose: 5000,
        });
        this.getMyTaps();
      })
      .catch((err) => {
        toast.error("There was an internal issue creating your data tap.", {
          autoClose: 5000,
        });
      })
      .finally(() => {
        toast.dismiss(toastID);
        this.setState({ progress: 100 });
      });
  };

  fetchInitialTapData = async () => {
    let id = new URLSearchParams(window.location.search).get("id");
    let details = new URLSearchParams(window.location.search).get("details");
    let edit_tap = new URLSearchParams(window.location.search).get("edit_tap");
    let isEditingReport = new URLSearchParams(window.location.search).get(
      "custom_reports"
    );

    if (
      edit_tap &&
      edit_tap === this.state.selected_for_edit &&
      !isEditingReport
    ) {
      return;
    }

    if (
      edit_tap &&
      edit_tap === this.state.selected_for_edit &&
      isEditingReport
    ) {
      this.getGACustomReports();
      return;
    }
    if (id) {
      let tap_alias = details.split("___")[2];
      let connector_name = details.split("___")[1];
      let schema = details.split("___")[0];
      this.createFivetranTap(id, connector_name, schema, tap_alias);
    } else if (edit_tap) {
      this.setState({ selected_for_edit: edit_tap });
      this.toggleCreateTapForm();
      await this.getMyTaps();
      console.log(this.state.mytasks);
      let item = this.state.mytasks.find((item) => item.id === edit_tap);
      if (item) {
        let { connectionID, logo, sourceID, subtype } = item;
        this.setState({
          connection_id: connectionID,
          source_ID: sourceID,
          selected_tap: subtype,
          logo: logo,
          selected_for_edit: edit_tap,
          selected_source_ID: sourceID,
        });

        if (isEditingReport) {
          this.getGACustomReports();
        }
      }
    } else {
      this.setState({
        connection_id: "",
        source_ID: "",
        selected_tap: "",
        logo: "",
        selected_for_edit: "",
        isShowingCreateTapForm: false,
      });
      await this.getMyTaps();

      if (this.getTargetDAGData()) {
        this.toggleHistoryDagTable();
      }
    }
  };

  getGACustomReports = () => {
    this.setState({ custom_reports: [] });
    var toastID = toast.loading("Retrieving custom reports...");
    axios
      .post(config.url.TAPS_URL + "/api/airbyte/get_existing_specs", {
        sourceID: this.state.selected_source_ID,
        dag_name: this.state.selected_for_edit,
      })
      .then((response) => {
        let window_in_days;
        try {
          window_in_days = JSON.parse(
            response.data["specs"]["connectionConfiguration"]["window_in_days"]
          );
        } catch (error) {
          window_in_days = 1;
        }
        this.setState({
          specs: response.data["specs"],
          window_in_days: window_in_days,
        });
        try {
          console.log(
            response.data["specs"]["connectionConfiguration"]["custom_reports"]
          );
          this.setState({
            custom_reports: JSON.parse(
              response.data["specs"]["connectionConfiguration"][
                "custom_reports"
              ]
            ),
          });
        } catch (error) {
          console.log(
            "Unable to parse existing custom reports. There may be none or a parsing issue"
          );
          this.setState({ custom_reports: [] });
        }
        this.setState({ modal_custom_reports: true });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Unable to retrieve custom reports.", { autoClose: 5000 });
      })
      .finally(() => {
        toast.dismiss(toastID);
      });
  };

  calculateTotalPages = () => {
    const totalLength = this.state.mytasks.filter((DAG) =>
      this.filterJobNames(DAG)
    ).length;
    return totalLength ? Math.ceil(totalLength / this.state.perPage) : 1;
  };

  paginateArray = (
    array,
    activePage = this.state.currentPage,
    perPageCount = this.state.perPage
  ) => {
    const startIndex = (activePage - 1) * perPageCount;
    const endIndex = startIndex + perPageCount;

    return array.slice(startIndex, endIndex);
  };

  goToSchedule = () => {
    this.setState({
      progress: 40,
      saving_settings: true,
      saving_disabled: true,
    });
    this.setState({
      progress: 100,
      saving_settings: false,
      saving_disabled: false,
      activeTab: "schedule",
    });
  };

  toggleDeleteModal = (
    connector_id,
    sourceID,
    logo,
    connector_name,
    datajolt_task_id
  ) => {
    this.setState({
      modal_delete_connector: !this.state.modal_delete_connector,
    });
    if (!this.state.modal_delete_connector) {
      this.setState({
        selected_source_ID: sourceID,
        delete_logo: logo,
        delete_name: connector_name,
        delete_id: connector_id,
        delete_datajolt_task_id: datajolt_task_id,
      });
    }
  };

  getMyTaps = (activeTab = "mytaps") => {
    return new Promise((resolve, reject) => {
      this.setState({
        get_dags_status: true,
        global_replicate: false,
        selected_streams: [],
        global_stream_selected: false,
        global_selected_sync: "",
        tap_alias: "",
        loading_mytaps: true,
        progress: 60,
        logs: false,
        streams: [],
        loading_spinner: true,
        replication_disabled: true,
        activeTab: activeTab,
      });
      axios
        .post(config.url.AIRFLOW + "/api/airflow/list_tasks_from_pg", {
          dag_type: "Taps",
        })
        .then((response) => {
          this.setState({
            show_schedule_button: false,
            mytasks: response.data["dags_list"],
          });
          console.log("DAGS")
          console.log(response.data["dags_list"]);
          for (var dag in response.data["dags_list"]) {
            if (
              response.data["dags_list"][dag]["status"] == "running" ||
              response.data["dags_list"][dag]["status"] == "queued"
            ) {
              fetchLastRuns(
                response.data["dags_list"][dag]["datajolt_task_id"],
                1,
                this.setState.bind(this),
                () => this.isComponentMounted
              );
            }
          }
          resolve();
        })
        .catch((err) => {
          toast.error("There was an issue listing your data taps.", {
            autoClose: 5000,
          });
        })
        .finally(() => {
          this.setState({ progress: 100, loading_mytaps: false });
        });
    });
  };

  debounce(fn, delay) {
    let timerId;
    return function (...args) {
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(() => {
        fn(...args);
        timerId = null;
      }, delay);
    };
  }

  updateCustomReport = this.debounce((index, key, e, val) => {
    var current = [...this.state.custom_reports];
    current[index][key] = val || e.target.value;
    this.setState({ custom_reports: current });
  }, 50);

  triggerAirbyteTap = (dag_id, connection_id, datajolt_task_id) => {
    var toastID = toast.loading("Triggering tap " + dag_id + "...");
    axios
      .post(config.url.TAPS_URL + "/api/airbyte/trigger_job", {
        run_type: "Triggered",
        connection_id: connection_id,
        datajolt_task_id: datajolt_task_id,
      })
      .then(() => {
        toast.success("Job triggered successfully."), { autoClose: 5000 };
        const nowUTC = moment().utc().format("YYYY-MM-DD HH:mm");
        this.setState((prevState) => {
          const updatedMytaps = prevState.mytasks.map((tap) => {
            if (tap.id === dag_id) {
              return {
                ...tap,
                last_started: nowUTC,
                rows: "",
                last_ended: "None",
                status: "running",
              };
            }
            return tap;
          });
          return { mytasks: updatedMytaps };
        });
        fetchLastRuns(
          datajolt_task_id,
          1,
          this.setState.bind(this),
          () => this.isComponentMounted
        );
      })
      .catch((err) => {
        toast.error("Error triggering your job.", { autoClose: 5000 });
      })
      .finally(() => {
        toast.dismiss(toastID);
      });
  };

  toggle_modal_daily_hour = () => {
    this.setState({ modal_daily_hour: !this.state.modal_daily_hour });
  };

  toggle_modal_cron = () => {
    this.setState({ modal_cron: !this.state.modal_cron });
  };

  deleteConnector = (datajolt_task_id) => {
    var toastID = toast.loading("Deleting your tap...");
    this.setState({ deleting: true, color: "primary", progress: 40 });
    axios
      .post(config.url.TAPS_URL + "/api/fivetran/delete_connector", {
        datajolt_task_id: datajolt_task_id,
      })
      .then((response) => {
        this.setState({ modal_delete_connector: false });
        toast.success(
          "It will take a few seconds to be reflected in Datajolt.",
          { autoClose: 5000 }
        );
        toast.success("Tap successfully deleted", { autoClose: 5000 });
        this.getMyTaps();
      })
      .catch((err) => {
        toast.error("There was an issue deleting your tap.");
      })
      .finally(() => {
        toast.dismiss(toastID);
        this.setState({ deleting: false, progress: 100 });
      });
  };

  deleteDAG = (datajolt_task_id, alias) => {
    var toastId = toast.loading("Deleting " + alias + "...");
    this.setState({ deleting: true,progress: 80 });
    axios
      .post(config.url.TAPS_URL + "/api/taps/delete_tap", {
        tap_id: datajolt_task_id,
        tap_alias:alias
      })
      .then((response) => {
        toast.success(alias + " deleted successfully. ", { autoClose: 5000 });
        this.getMyTaps();
      })
      .catch((err) => {
        toast.error("Could not delete your tap  " + alias + ".", {
          autoClose: 5000,
        });
      })
      .finally(() => {
        this.setState({
          progress: 100,
          modal_delete_connector: false,
          deleting: false,
        });
        toast.dismiss(toastId);
      });
  };

  triggerFivetran = (datajolt_task_id, alias) => {
    var toastID = toast.loading("Triggering " + alias + "...");
    this.setState({ progress: 40 });
    axios
      .post(config.url.TAPS_URL + "/api/fivetran/trigger_connector", {
        datajolt_task_id: datajolt_task_id,
      })
      .then((response) => {
        toast.success("Your data tap has been triggered !", {
          autoClose: 5000,
        });
        const nowUTC = moment().utc().format("YYYY-MM-DD HH:mm");
        this.setState((prevState) => {
          const updatedMytaps = prevState.mytasks.map((tap) => {
            if (tap.datajolt_task_id === datajolt_task_id) {
              return {
                ...tap,
                last_started: nowUTC,
                rows: "",
                last_ended: "None",
                status: "running",
              };
            }
            return tap;
          });
          return { mytasks: updatedMytaps };
        });
        fetchLastRuns(
          datajolt_task_id,
          1,
          this.setState.bind(this),
          () => this.isComponentMounted
        );
      })
      .catch((err) => {
        toast.error("There was an issue triggering your Fivetran tap.", {
          autoClose: 5000,
        });
      })
      .finally(() => {
        toast.dismiss(toastID);
        this.setState({ progress: 100 });
      });
  };

  validate_custom_reports = () => {
    const isUnique =
      [...new Set(this.state.custom_reports.map((obj) => obj.name))].length ===
      this.state.custom_reports.length;
    if (!isUnique) {
      toast.error("Custom report names have to be unique", { autoClose: 5000 });
      return false;
    } else {
      for (let obj of this.state.custom_reports) {
        let dimensions = [];
        let metrics = [];
        if (!Array.isArray(obj["dimensions"])) {
          dimensions = obj["dimensions"].split(",");
        } else {
          dimensions = obj["dimensions"];
        }
        if (!Array.isArray(obj["metrics"])) {
          metrics = obj["metrics"].split(",");
        } else {
          metrics = obj["metrics"];
        }

        if (obj["name"] == "") {
          toast.error("Your custom report name cannot be empty.", {
            autoClose: 5000,
          });
          return false;
        } else if (dimensions.length == 0 || dimensions == "") {
          toast.error(
            "Your " +
              obj["name"] +
              " custom report dimensions cannot be empty.",
            { autoClose: 5000 }
          );
          return false;
        } else if (metrics.length == 0 || metrics == "") {
          toast.error(
            "Your " + obj["name"] + " custom report metrics cannot be empty.",
            { autoClose: 5000 }
          );
          return false;
        } else if (dimensions.length > 9) {
          toast.error(
            "Your " +
              obj["name"] +
              " custom report cannot have more than 9 dimensions.",
            { autoClose: 5000 }
          );
          return false;
        } else if (metrics.length > 10) {
          toast.error(
            "Your " +
              obj["name"] +
              " custom report cannot have more than 10 metrics.",
            { autoClose: 5000 }
          );
          return false;
        }
      }
      return true;
    }
  };

  discoverOrUpdate = (type) => {
    this.setState({
      global_replicate: false,
      selected_streams: [],
      global_selected_sync: "",
      global_stream_selected: false,
    });
    if (type == "schedule") {
      this.setState({ selected_for_edit: false });
      this.discoverSchema(this.state.selected_source_ID, "schedule");
    }
  };

  discoverSchema = (sourceID, type) => {
    var toastID = toast.loading(
      "Retrieving data structure from your " +
        this.state.selected_tap +
        " tap..."
    );
    if (type != "schedule") {
      this.setState({ show_schedule_button: false });
    }
    this.setState({
      streams: [],
      selected_source_ID: sourceID,
      selection_type: type,
      saving_disabled: true,
      progress: 40,
      replication_disabled: false,
      loading_spinner: true,
      activeTab: "data",
    });
    const SelectedTap = {
      sourceID: sourceID,
      tap_name: this.state.selected_tap,
      destination: false,
    };
    axios
      .post(config.url.TAPS_URL + "/api/airbyte/discover_schema", SelectedTap)
      .then((response) => {
        this.setState({
          saving_disabled: false,
          streams: response.data["streams"],
          catalogID: response.data["catalogID"],
          global_supportedSyncModes: response.data["global_supportedSyncModes"],
        });
      })
      .catch((err) => {
        toast.error(
          "There was an issue getting metadata from your data source..."
        );
        this.setState({ spec_details: [] });
      })
      .finally(() => {
        toast.dismiss(toastID);
        this.setState({ progress: 100, loading_spinner: false });
      });
  };

  saveCustomReports = () => {
    if (this.validate_custom_reports()) {
      var toastID = toast.loading("Updating custom reports..");
      this.setState({ saving_new_specs: true });
      var current_specs = this.state.specs;
      current_specs["connectionConfiguration"]["window_in_days"] =
        this.state.window_in_days;
      current_specs["connectionConfiguration"]["custom_reports"] =
        JSON.stringify(this.state.custom_reports);
      axios
        .post(config.url.TAPS_URL + "/api/airbyte/update_custom_report", {
          specs: current_specs,
          dag_name: this.state.selected_for_edit,
        })
        .then((response) => {
          this.setState({ modal_custom_reports: false });
          this.discoverOrUpdate(this.state.selection_type);
        })
        .catch((err) => {
          toast.error("Unable to update custom reports.", { autoClose: 5000 });
        })
        .finally(() => {
          this.props.router.navigate(
            `/taps/mytaps?edit_tap=${this.state.selected_for_edit}`
          );
          this.setState({ loading: false, saving_new_specs: false });
          toast.dismiss(toastID);
        });
    }
  };

  saveOrSchedule = (type) => {
    if (type == "schedule") {
      this.goToSchedule();
    }
  };

  handleStateUpdate = (newState) => {
    this.setState(newState);
  };

  toggleCreateTapForm = () => {
    this.setState(
      {
        isShowingCreateTapForm: !this.state.isShowingCreateTapForm,
      },
      () => {
        if (!this.state.isShowingCreateTapForm) {
          this.props.router.navigate("/taps/mytaps");
        }
      }
    );
  };

  redirectToHistoryModalView = (DAG, event) => {
    // Prevent any parent click events from interfering
    if (event) {
        event.preventDefault();
        event.stopPropagation();
    }
    console.log('History modal triggered for DAG ID: ', DAG.id);
    this.props.router.navigate(`/taps/mytaps?history_modal=${DAG.id}`);
  };

  updateSearchConnectorsKeyword = (e) => {
    this.setState({ searchConnectorsKeyword: e.target.value });
  };
  handleClearSearchConnectorsKeyword = () => {
    this.setState({ searchConnectorsKeyword: "" });
  };

  updateJobNameFilters = (filters) =>
    this.setState({ jobNameFilters: filters });

  filterJobNames = (DAG) => {
    const target = DAG.id;
    if (this.state.jobNameFilters) {
      if (target.includes(this.state.jobNameFilters)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  onSortColumn = (column) => {
    const tasks = this.state.mytasks.map((task) => ({ ...task }));
    let isAsc = true;

    if (this.state.sortedColumn.column === column) {
      isAsc = !this.state.sortedColumn.isAsc;
    }

    this.setState({
      mytasks: tasks.sort((a, b) => {
        if (isAsc) {
          return a[column]?.toLowerCase() < b[column]?.toLowerCase() ? 1 : -1;
        } else {
          return b[column]?.toLowerCase() < a[column]?.toLowerCase() ? 1 : -1;
        }
      }),
    });
    this.setState({ sortedColumn: { column, isAsc } });
  };

  render() {
    const { navigate } = this.props.router;
    return (
      <div>
        <LoadingBar color="#0245a0" progress={this.state.progress} />
        <div className="container-fluid py-3">
          <Helmet title="Datajolt | Data Taps" />
          <Tab.Container id="taps-tabs" activeKey={this.state.activeTab}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "12px 8px",
                height: "45px",
              }}
            >
              <h4 style={{ marginBottom: 0 }}>
                <b style={{ color: "#090E47", marginRight: "10px" }}>
                  My Data Taps
                  {!this.state.loading_mytaps
                    ? " (" + this.state.mytasks.length + ")"
                    : ""}
                </b>
                <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  overlay={
                    <Popover id={`popover-positioned-bottom`}>
                      <Popover.Header as="h3">
                        {"What are Data Taps?"}
                      </Popover.Header>
                      <Popover.Body>
                        Data Taps are source systems creating your business
                        data.
                        <br />
                        <br />
                        This page allows you to create a data refresh from your
                        source systems to your DWH so your data can then be
                        transformed and analysed.
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <DetailsIcon />
                </OverlayTrigger>{" "}
              </h4>

              {this.state.activeTab === "mytaps" && (
                <div
                  style={{
                    display: "flex",
                    marginLeft: "auto",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  {this.state.isShowingCreateTapForm &&
                  this.state.logo &&
                  this.state.selected_tap ? (
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <img
                        style={{
                          width: "50px",
                          height: "50px",
                          objectFit: "contain",
                        }}
                        src={this.state.logo}
                      />
                      <b
                        style={{
                          marginBottom: "0",
                          fontWeight: "bold",
                          color: "#090E47",
                          fontSize: "18px",
                        }}
                      >
                        {this.state.selected_tap}
                      </b>
                    </span>
                  ) : (
                    <>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip id={`tooltip-bottom`}>Refresh Taps</Tooltip>
                        }
                      >
                        <div
                          className="me-1 mb-1 mr-2"
                          disabled={this.state.loading}
                          onClick={() => this.componentDidMount()}
                        >
                          {this.state.loading_mytaps ? (
                            <Spinner
                              size="sm"
                              variant="secondary"
                              animation="border"
                            />
                          ) : (
                            <RefreshIcon />
                          )}
                        </div>
                      </OverlayTrigger>
                      <Button
                        variant="generaltabs"
                        className="active lighter-hover"
                        onClick={this.toggleCreateTapForm}
                        style={{
                          padding: "5px 7px",
                          borderRadius: "5px",
                          background: "#2D49FD",
                        }}
                      >
                        Create a Tap{" "}
                        <Icon.RefreshCw
                          style={{ marginLeft: "5px" }}
                          width={14}
                          color="white"
                        />
                      </Button>
                    </>
                  )}
                </div>
              )}
            </div>
            <Tab.Content>
              <Tab.Pane eventKey="mytaps">
                <hr style={{ margin: "0 8px" }} />
                <Col md="12">
                  {!this.state.loading_mytaps &&
                  this.state.mytasks.length == 0 ? (
                    <>
                      <br />
                      <br />
                      <center>
                        <h4>You currently have no data taps.</h4>
                        <br />
                      </center>
                      <br />
                    </>
                  ) : (
                    <>
                      <table className="table-lists table-daged table-bordered table-triped table-hover my-8px">
                        <thead>
                          <tr>
                            <th width="6%"></th>
                            <th id="th-name" className="resizables">
                              <div className="d-flex justify-content-start gap-1 align-items-center h-100">
                                <span>Job Name </span>
                                <SearchJobs
                                  onFilterChange={this.updateJobNameFilters}
                                />
                                <SortBtn
                                  onClick={() => this.onSortColumn("id")}
                                  isSorted={
                                    this.state.sortedColumn.column === "id"
                                      ? this.state.sortedColumn.isAsc
                                        ? "asc"
                                        : "desc"
                                      : "none"
                                  }
                                />
                              </div>
                            </th>
                            <th className="resizables">
                              <span className="d-flex align-items-center h-100">
                                Source
                                <SortBtn
                                  onClick={() => this.onSortColumn("source")}
                                  isSorted={
                                    this.state.sortedColumn.column === "source"
                                      ? this.state.sortedColumn.isAsc
                                        ? "asc"
                                        : "desc"
                                      : "none"
                                  }
                                />
                              </span>
                            </th>
                            <th className="resizables">
                              <span className="d-flex align-items-center h-100">
                                Schema
                                <SortBtn
                                  onClick={() => this.onSortColumn("schema")}
                                  isSorted={
                                    this.state.sortedColumn.column === "schema"
                                      ? this.state.sortedColumn.isAsc
                                        ? "asc"
                                        : "desc"
                                      : "none"
                                  }
                                />
                              </span>
                            </th>
                            <th className="resizables">
                              <span className="d-flex align-items-center h-100">
                                last started
                                <SortBtn
                                  onClick={() =>
                                    this.onSortColumn("last_started")
                                  }
                                  isSorted={
                                    this.state.sortedColumn.column ===
                                    "last_started"
                                      ? this.state.sortedColumn.isAsc
                                        ? "asc"
                                        : "desc"
                                      : "none"
                                  }
                                />
                              </span>
                            </th>
                            <th className="resizables">
                              <span className="d-flex align-items-center h-100">
                                last ended
                                <SortBtn 
                                  onClick={() => this.onSortColumn("last_ended")}
                                  isSorted={
                                    this.state.sortedColumn.column === "last_ended"
                                      ? this.state.sortedColumn.isAsc
                                        ? "asc"
                                        : "desc"
                                      : "none"
                                  }
                                />
                              </span>
                            </th>
                            {/* <th className="resizables">rows</th> */}
                            <th className="resizables">
                              <span className="d-flex align-items-center h-100">
                                status
                                <SortBtn 
                                  onClick={() => this.onSortColumn("status")}
                                  isSorted={
                                    this.state.sortedColumn.column === "status"
                                      ? this.state.sortedColumn.isAsc
                                        ? "asc"
                                        : "desc"
                                      : "none"
                                  }
                                />
                              </span>
                            </th>
                            <th className="resizables">provider</th>
                            <th width="6%">actions</th>
                          </tr>
                        </thead>
                        {this.state.loading_mytaps ? (
                          <LoadingTableBody colNum={10} />
                        ) : (
                          <tbody>
                            {this.state.mytasks.length > 0 &&
                              this.paginateArray(
                                this.state.mytasks.filter((DAG) =>
                                  this.filterJobNames(DAG)
                                )
                              ).map((DAG, index) => (
                                <tr key={DAG.id + index}>
                                  <td width="3%">
                                    {DAG.logo && (
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: 5,
                                          alignItems: "center",
                                        }}
                                      >
                                        <img
                                          className="rounded-table-logo"
                                          style={{
                                            transform: "scale(0.8)",
                                          }}
                                          src={DAG.logo}
                                        ></img>
                                        <span
                                          style={{
                                            width: "1.5px",
                                            height: "50px",
                                            background: "#EBEBEB",
                                            display: "block",
                                          }}
                                        />
                                      </div>
                                    )}
                                  </td>
                                  <td width="20%" className="text-break">
                                    {DAG.id}
                                  </td>
                                  <td width="20%" className="text-break">
                                    {DAG.source}
                                  </td>
                                  <td width="16%" className="text-break">
                                    {" "}
                                    {DAG.schema}
                                  </td>
                                  <td width="13%">
                                    {DAG.last_started == "None"
                                      ? ""
                                      : moment
                                          .utc(DAG.last_started)
                                          .tz(customer_timezone)
                                          .format("YYYY-MM-DD HH:mm")}
                                  </td>
                                  <td width="13%">
                                    {DAG.last_ended == "None"
                                      ? ""
                                      : moment
                                          .utc(DAG.last_ended)
                                          .tz(customer_timezone)
                                          .format("YYYY-MM-DD HH:mm")}
                                  </td>
                                  {/* <td width="14%">
                                      {DAG.rows == 'None' ? '' : DAG.rows}
                                    </td> */}
                                  <td width="1%">
                                    {DAG.status == "failed" ||
                                    DAG.status == "cancelled" ? (
                                      <OverlayTrigger
                                        rootClose
                                        trigger={["hover", "hover"]}
                                        placement="bottom"
                                        overlay={
                                          <Tooltip id={`tooltip-bottom`}>
                                            {DAG.status}
                                          </Tooltip>
                                        }
                                      >
                                        <FailedIcon className="centered-svg-block" />
                                      </OverlayTrigger>
                                    ) : DAG.status == "success" ? (
                                      <OverlayTrigger
                                        rootClose
                                        trigger={["hover", "hover"]}
                                        placement="bottom"
                                        overlay={
                                          <Tooltip id={`tooltip-bottom`}>
                                            Success
                                          </Tooltip>
                                        }
                                      >
                                        <SuccessIcon className="centered-svg-block" />
                                      </OverlayTrigger>
                                    ) : DAG.status == "queued" ? (
                                      <OverlayTrigger
                                        rootClose
                                        trigger={["hover", "hover"]}
                                        placement="bottom"
                                        overlay={
                                          <Tooltip id={`tooltip-bottom`}>
                                            Queued
                                          </Tooltip>
                                        }
                                      >
                                        <QueuedIcon className="centered-svg-block" />
                                      </OverlayTrigger>
                                    ) : DAG.status == "running" ? (
                                      <OverlayTrigger
                                        rootClose
                                        trigger={["hover", "hover"]}
                                        placement="bottom"
                                        overlay={
                                          <Tooltip id={`tooltip-bottom`}>
                                            Running
                                          </Tooltip>
                                        }
                                      >
                                        <RunningLottie className="centered-svg-block" />
                                      </OverlayTrigger>
                                    ) : (
                                      DAG.status
                                    )}
                                  </td>
                                  <td width="5%" className="text-break">
                                    {DAG.provider == "Fivetran" && (
                                      <img
                                        src={fivetranlogo}
                                        width="30px"
                                        alt="Fivetran Logo"
                                        style={{ display: 'block' }}
                                      />
                                    )}
                                    {DAG.provider == "AB Hosted" && (
                                      <img
                                        src={airbytelogo}
                                        width="30px"
                                        alt="Airbyte Logo"
                                        style={{ display: 'block' }}
                                      />
                                    )}

                                    {DAG.provider != "Fivetran" && DAG.provider != "AB Hosted" && (
                                      <img
                                        src={djlogo}
                                        width="60px"
                                        alt="Default Logo"
                                        style={{ display: 'block' }}
                                      />
                                    )}
                                  </td>

                                  <td
                                    style={{
                                      width: "2%",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    <HorizontalDropdownMenu>
                                      <OverlayTrigger
                                        rootClose
                                        trigger={["hover", "hover"]}
                                        placement="bottom"
                                        overlay={
                                          <Tooltip id={`tooltip-bottom`}>
                                            Trigger Tap
                                          </Tooltip>
                                        }
                                      >
                                        <Button
                                          size="sm"
                                          variant="no-outline-success"
                                          disabled={
                                            DAG.status == "running"
                                              ? true
                                              : false
                                          }
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            DAG.provider == "Fivetran"
                                              ? this.triggerFivetran(
                                                  DAG.datajolt_task_id,
                                                  DAG.id
                                                )
                                              : this.triggerAirbyteTap(
                                                  DAG.id,
                                                  DAG.connectionID,
                                                  DAG.datajolt_task_id
                                                );
                                          }}
                                        >
                                          {DAG.status == "running" ? (
                                            <Spinner
                                              style={{
                                                marginLeft: "4px",
                                                marginRight: "4px",
                                                marginTop: "2px",
                                              }}
                                              as="span"
                                              variant="success"
                                              size="sm"
                                              role="status"
                                              aria-hidden="true"
                                              animation="border"
                                            />
                                          ) : (
                                            <PlayIcon />
                                          )}
                                        </Button>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        rootClose
                                        trigger={["hover", "hover"]}
                                        placement="bottom"
                                        overlay={
                                          <Tooltip id={`tooltip-bottom`}>
                                            Edit Tap
                                          </Tooltip>
                                        }
                                      >
                                        <Button
                                          size="sm"
                                          variant="no-outline"
                                          disabled={DAG.provider != "AB Hosted"}
                                          className="me-1 mb-1"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            navigate("/taps/mytaps?edit_tap=" + DAG.id);
                                          }}
                                        >
                                          <SettingsIcon className="bigger-svg" />
                                        </Button>
                                      </OverlayTrigger>

                                      <OverlayTrigger
                                        rootClose
                                        trigger={["hover", "hover"]}
                                        placement="bottom"
                                        overlay={
                                          <Tooltip id={`tooltip-bottom`}>
                                            Open History
                                          </Tooltip>
                                        }
                                      >
                                        <Button
                                          size="sm"
                                          variant="no-outline"
                                          className="me-1 mb-1"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            this.redirectToHistoryModalView(DAG, e);
                                        }}
                                        onMouseDown={(e) => console.log("Button mousedown event")}
                                      >
                                          <OptionsIcon />
                                        </Button>
                                      </OverlayTrigger>

                                      <OverlayTrigger
                                        rootClose
                                        trigger={["hover", "hover"]}
                                        placement="bottom"
                                        overlay={
                                          <Tooltip id={`tooltip-bottom`}>
                                            Stop Tap
                                          </Tooltip>
                                        }
                                      >
                                        <Button
                                          size="sm"
                                          variant="no-outline-danger"
                                          className="me-1 mb-1"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            this.handleStopTask(
                                              DAG.id,
                                              DAG.datajolt_task_id
                                            );
                                          }}
                                        >
                                          <StopIcon />
                                        </Button>
                                      </OverlayTrigger>

                                      <OverlayTrigger
                                        rootClose
                                        trigger={["hover", "hover"]}
                                        placement="bottom"
                                        overlay={
                                          <Tooltip id={`tooltip-bottom`}>
                                            Delete Tap
                                          </Tooltip>
                                        }
                                      >
                                        <Button
                                          size="sm"
                                          variant="no-outline-danger"
                                          className="mb-1"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            this.toggleDeleteModal(
                                              DAG.id,
                                              DAG.sourceID,
                                              DAG.logo,
                                              DAG.subtype,
                                              DAG.datajolt_task_id
                                            );
                                          }}
                                        >
                                          <CancelIcon />
                                        </Button>
                                      </OverlayTrigger>
                                    </HorizontalDropdownMenu>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        )}
                      </table>
                      <TablePagination
                        currentPage={this.state.currentPage}
                        totalPages={this.calculateTotalPages()}
                        updatePageNum={(num) =>
                          this.setState({ currentPage: num })
                        }
                        perPage={this.state.perPage}
                        updatePerPage={(num) => this.setState({ perPage: num })}
                        activeTable={this.state.jobNameFilters}
                      />
                    </>
                  )}
                </Col>
              </Tab.Pane>
            </Tab.Content>

            <Modal
              show={this.state.modal_custom_reports}
              onHide={() => {
                this.setState({ modal_custom_reports: false });
                navigate(
                  `/taps/mytaps?edit_tap=${this.state.selected_for_edit}`
                );
              }}
              size="lg"
              centered
            >
              <Modal.Header closeButton>
                <img
                  src={this.state.logo}
                  width="5%"
                  style={{ marginTop: "-3px", marginLeft: "5px" }}
                ></img>
                &nbsp;<b>Customize Google Analytics</b>
              </Modal.Header>
              <Modal.Body className="text-left m-2">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b style={{ marginLeft: "20px" }}>Window in days</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    style={{ marginLeft: "20px", width: "97%" }}
                    key={"window"}
                    defaultValue={this.state.window_in_days}
                    placeholder="30,60,90,120,200,364"
                    onChange={(e) =>
                      this.setState({
                        window_in_days: parseInt(e.target.value, 10),
                      })
                    }
                  />
                  <br />
                  <Form.Label>
                    <b style={{ marginLeft: "20px" }}>
                      Custom Reports ({this.state.custom_reports.length})
                    </b>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                      onClick={() =>
                        this.setState({
                          custom_reports: [
                            ...this.state.custom_reports,
                            { name: "", metrics: "", dimensions: "" },
                          ],
                        })
                      }
                      variant="generaltabs"
                      size="sm-pad"
                      className="active"
                    >
                      +
                    </Button>
                  </Form.Label>
                </Form.Group>
                {this.state.custom_reports.map((custom_report, index) => (
                  <>
                    <Accordion
                      defaultActiveKey={custom_report["name"] ? null : "0"}
                      style={{ marginLeft: "20px" }}
                    >
                      <Accordion.Item
                        key={"report_" + custom_report["id"]}
                        eventKey="0"
                        className="bg-white"
                      >
                        <Accordion.Header>
                          {custom_report["name"]}
                        </Accordion.Header>
                        <Accordion.Body>
                          <b>Report Name</b>
                          <Form.Control
                            style={{ width: "97%" }}
                            key={"report_" + custom_report["id"]}
                            type={"text"}
                            defaultValue={custom_report["name"]}
                            placeholder="LTV Report"
                            onChange={(e) =>
                              this.updateCustomReport(index, "name", e)
                            }
                          />

                          <b>Dimensions</b>
                          <Form.Control
                            style={{ width: "97%" }}
                            key={"dimensions_" + custom_report["id"]}
                            type={"text"}
                            defaultValue={custom_report["dimensions"]}
                            placeholder={
                              this.state.selected_tap == "Google Analytics"
                                ? "ga:date, ga:country"
                                : "date, country"
                            }
                            onChange={(e) =>
                              this.updateCustomReport(index, "dimensions", e)
                            }
                          />
                          <b>Metrics</b>
                          <Form.Control
                            style={{ width: "97%" }}
                            key={"metrics_" + index}
                            type={"text"}
                            defaultValue={custom_report["metrics"]}
                            placeholder={
                              this.state.selected_tap == "Google Analytics"
                                ? "ga:activeUsers"
                                : "activeUsers"
                            }
                            onChange={(e) =>
                              this.updateCustomReport(index, "metrics", e)
                            }
                          />
                          <br />
                          <Button
                            onClick={() =>
                              this.setState({
                                custom_reports:
                                  this.state.custom_reports.filter(
                                    (item) => item.id !== custom_report["id"]
                                  ),
                              })
                            }
                            variant="danger"
                            size="sm-pad"
                            className="active"
                          >
                            Delete Report
                          </Button>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </>
                ))}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="primary"
                  disabled={
                    this.state.saving_new_specs ||
                    this.state.custom_reports.length == 0
                      ? true
                      : false
                  }
                  onClick={() => this.saveCustomReports()}
                >
                  <Icon.Save size={16} />
                  &nbsp;
                  {this.state.saving_new_specs ? (
                    <>Saving...&nbsp;&nbsp;</>
                  ) : (
                    "Save & Refresh"
                  )}
                  {this.state.saving_new_specs ? (
                    <Spinner
                      as="span"
                      variant="light"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      animation="border"
                    />
                  ) : (
                    ""
                  )}
                </Button>
                &nbsp;&nbsp;
                <Button
                  variant="secondary"
                  disabled={false}
                  onClick={() => {
                    this.setState({ modal_custom_reports: false });
                    navigate(
                      `/taps/mytaps?edit_tap=${this.state.selected_for_edit}`
                    );
                  }}
                >
                  <Icon.X size={16} />
                  &nbsp;Close
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal
              show={this.state.modal_delete_connector}
              onHide={() => this.toggleDeleteModal()}
              centered
            >
              <Modal.Header closeButton>
                <b>Warning!</b>
                <img
                  src={this.state.delete_logo}
                  width="5%"
                  style={{
                    marginTop: "-3px",
                    position: "absolute",
                    right: "40px",
                  }}
                ></img>
              </Modal.Header>
              <Modal.Body className="text-center m-2">
                <center>
                  You are about to delete your{" "}
                  <b>
                    {this.state.delete_name} ({this.state.delete_id})
                  </b>{" "}
                  data tap.
                  <br />
                  <br />
                  <b>Are you sure ?</b>
                </center>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => this.toggleDeleteModal()}
                >
                  <Icon.X size={16} />
                  &nbsp; No
                </Button>{" "}
                <Button
                  variant="danger"
                  disabled={this.state.deleting}
                  onClick={() =>
                    this.deleteDAG(
                      this.state.delete_datajolt_task_id,
                      this.state.delete_id
                    )
                  }
                >
                  <Icon.Trash2 size={16} />
                  &nbsp;
                  {this.state.deleting ? (
                    <>
                      Deleting...&nbsp;&nbsp;
                      <Spinner
                        as="span"
                        variant="light"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        animation="border"
                      />
                    </>
                  ) : (
                    "Delete"
                  )}
                </Button>
              </Modal.Footer>
            </Modal>

          </Tab.Container>

          <CSSTransition
            in={this.state.isShowingDagHistoryTable}
            classNames="form-tab"
            timeout={300}
            unmountOnExit
          >
            <DagHistory
              dagList={Boolean(this.state.mytasks.length) && this.state.mytasks}
              onClose={() => {
                this.setState({
                  isShowingDagHistoryTable: false,
                });
                navigate("/taps/mytaps");
              }}
            />
          </CSSTransition>

          <CSSTransition
            in={this.state.isShowingCreateTapForm}
            classNames="form-tab"
            timeout={300}
            unmountOnExit
          >
            <TapCreatorForm
              getMyTaps={this.getMyTaps}
              mytasks={this.state.mytasks} // Pass mytasks as a prop
              setSelectedTapData={this.setSelectedTapData}
              isEditingTap={new URLSearchParams(window.location.search).get(
                "edit_tap"
              )}
              toggleCreateTapForm={this.toggleCreateTapForm}
            />
          </CSSTransition>
        </div>
      </div>
    );
  }
}

export default WithRouter(NewTap);
